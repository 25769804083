import { useEffect, useState } from "react";
import { Page, Body } from "blue-react";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Logout = () => {
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [wordPress, setWordPress] = useState("https://patorg.de");
  const [Backend, setBackend] = useState({ backend: "" });
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  // Checking if prod or debug
  // if (process.env.NODE_ENV === "production") {
  //   setBackend.backend("https://questions.patorg.de");
  //   setWordPress("https://patorg.de");
  // } else {
  //   setBackend.backend("http://localhost:5000/");
  //   setWordPress("http://localhost:81/");
  // }
  async function logOut() {
    let token = localStorage.getItem("token");

    const r = await fetch(wordPress + "/wp-json/jwt-auth/v1/token/revoke", {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
    });
    const c = await r.json();
    const cstatus = {
      status: r.status,
    };

    setStatus(cstatus.status);
    setLoading(false);
    console.log(status);
    localStorage.removeItem("token");
    localStorage.removeItem("status");
    removeCookie("token");
    removeCookie("status");
    navigate("/");
  }
  useEffect(() => {
    logOut();
  }, []);
  if (isLoading) {
    return (
      <Page>
        <Body>
          <div className="width-100 height-100 d-flex justify-content-center mx-auto p-2">
            <div class="spinner-border  " role="status">
              <span class="visually-hidden ">Loading...</span>
            </div>
          </div>
        </Body>
      </Page>
    );
  }
  return (
    <Page>
      <Body>
        <h1>Redirecting to Login...</h1>
      </Body>
    </Page>
  );
};
export default Logout;
