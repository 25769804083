import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Page, Header, HeaderTitle, Body } from "blue-react";
import { sha256 } from "js-sha256";
import { HistoryRouterProps } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";

const Login = () => {
  const [currentpassword, setCurrentPassword] = useState("");
  const [currentusername, setCurrentUsername] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [data, setData] = useState("");
  const [isloading, setIsLoading] = useState(false);
  const [passwrong, setPassWrong] = useState(false);
  const navigate = useNavigate();
  const [wordPress, setWordPress] = useState("https://patorg.de");
  const [save, setSave] = useState(false);
  // Checking if prod or debug
  // if (process.env.NODE_ENV === "production") {
  //   setBackend.backend("https://questions.patorg.de");
  //   setWordPress("https://patorg.de");
  // } else {
  //   setBackend.backend("http://localhost:5000/");
  //   setWordPress("http://localhost:81/");
  // }

  async function handleSubmit(event) {
    event.preventDefault();

    setIsLoading(true);
    setPassWrong(false);
    const r = await fetch(wordPress + "/wp-json/jwt-auth/v1/token", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        username: currentusername,
        password: currentpassword,
      }),
    });
    //console.log(r.status);
    const c = await r.json();
    const cstatus = {
      status: r.status,
    };
    if (r.status === 200) {
      localStorage.setItem("token", c.token);
      localStorage.setItem("status", r.status);
      if (save) {
        setCookie("token", c.token, { path: "/" });
        setCookie("status", r.status, { path: "/" });
      }
      setIsLoading(false);
      navigate("/home");
      window.location.reload(false);
    } else {
      console.log("Login Information Invalid. Status: ", r.status);
      setIsLoading(false);
      setPassWrong(true);
    }
  }

  return (
    <Page>
      <Body containerClass="container justify-content-center d-flex">
        <div className="sign-in-screen w-50 justify-content-center d-flex">
          <div className="sign-in-container mx-1 shadow rounded">
            <div className="rounded-3 px-4 py-5">
              <div className="text-center">
                <h1>Login</h1>
              </div>
              <form onSubmit={handleSubmit}>
                <br />
                <input
                  className="form-control mb-3 text-center"
                  id="username"
                  name="username"
                  placeholder="Benutzername"
                  type="username"
                  onChange={(e) => {
                    setCurrentUsername(e.target.value);
                    setPassWrong(false);
                  }}
                />
                <input
                  className="form-control mb-1 text-center"
                  id="password"
                  name="password"
                  placeholder="Passwort"
                  type="password"
                  onChange={(e) => {
                    setCurrentPassword(e.target.value);
                    setPassWrong(false);
                  }}
                />
                {passwrong ? (
                  <div
                    className="text-center fs-7 fw-light"
                    style={{ color: "red" }}
                  >
                    Password or Username is wrong
                  </div>
                ) : (
                  ""
                )}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="saveCheckbox"
                    value={save}
                    onChange={(e) => setSave(e.target.checked)}
                  />
                  <label className="form-check-label" htmlFor="saveCheckbox">
                    Save Login
                  </label>
                </div>

                {isloading ? (
                  <div className="width-100 height-100 d-flex justify-content-center mx-auto p-2">
                    <button class="btn" type="submit" disabled>
                      <span
                        class="spinner-border spinner-border-sm"
                        aria-hidden="true"
                      ></span>
                      <span role="status">Loading...</span>
                    </button>
                  </div>
                ) : (
                  <div className="width-100 height-100 d-flex justify-content-center mx-auto p-2">
                    <button type="submit" className="btn btn-light" href="/">
                      Login
                    </button>
                  </div>
                )}
              </form>
            </div>
          </div>
        </div>
      </Body>
    </Page>
  );
};
export default Login;

// {"http://localhost:3000/" + currentpassword + "/questions"}
