import { useState, useEffect, useRef, useReducer } from "react";
import { useLocation } from "react-router-dom";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import { PlainLight } from "survey-core/themes/plain-light";
import "survey-core/i18n/german";
import { themeJson } from "./theme";

const FragenStellen = () => {
  const location = useLocation();
  const ref = useRef(null);
  const [WhatToRender, setWhatToRender] = useState("");
  const [sendTo, setSendTo] = useState("");
  const [ID, setID] = useState("");

  useEffect(() => {
    async function init() {
      let res = await fetch(process.env.REACT_APP_BACKEND_URL + "/frageboegen");
      let frageboegen = await res.json();
      console.log("The received IDs: ", frageboegen);

      let id = location.pathname.split("/")[1];
      setID(id);
      console.log("The ID is: ", id);
      for (let i = 0; i < frageboegen.length; i++) {
        if (frageboegen[i].id === id) {
          console.log("FOUND IT");
          setWhatToRender(frageboegen[i].json);
          setSendTo(frageboegen[i].sendto);
        }
      }
    }
    init();
  }, [location]);

  const survey = new Model(WhatToRender);
  survey.applyTheme(themeJson);
  survey.locale = "de";

  survey.onComplete.add(async (result) => {
    console.log("Survey results: " + JSON.stringify(result.data));
    const adddata = await fetch(
      process.env.REACT_APP_BACKEND_URL + "/localstorage/",
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          data: JSON.stringify(result.data),
          id: ID,
          sendto: sendTo,
        }),
      }
    );
  });
  return (
    <div
      ref={ref}
      style={{
        fontSize: "x-large",
        fontFamily: "Work Sans",
        backgroundColor: "white",
        height: "100%",
      }}
    >
      {localStorage.clear()}
      <Survey model={survey} />
    </div>
  );
};
//<div dangerouslySetInnerHTML={{__html: WhatToRender}} />
//<Frage name="frage1" ans1="Antwort1" ans2="Antwort2" nxtq="frage2"/>
//<Interweave content={WhatToRender} />
export default FragenStellen;
