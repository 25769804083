import { Route, Routes } from "react-router-dom";

// import pages
import Login from "./pages/Login";
import Logout from "./pages/Logout";
import Home from "./pages/Home";
import SurveyCreator from "./pages/SurveyCreator";
//import components
import CheckForLogin from "./components/CheckForLogin";
import FragenStellen from "./pages/Fragenstellen";
import SurveyEditor from "./pages/SurveyEditor";
import Images from "./pages/Images";

function App() {
  return (
    <>
      {/* the checkforlogin component checks if the baseurl youre on is either
        - /meinefragen
        - /questions
        if the statement returns true the sidebar and co is being rendered
      */}
      <CheckForLogin>
        <div className="router-page active">
          <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/:id?" element={<FragenStellen />} />
            <Route path="/home/:id?" element={<Home />} />
            <Route path="/logout" element={<Logout />} />
            <Route path="/creator" element={<SurveyCreator />} />
            <Route path="/edit/:id" element={<SurveyEditor />} />
            <Route path="/image/:id" element={<Images />} />
          </Routes>
        </div>
      </CheckForLogin>
    </>
  );
}

export default App;
