import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HashRouter } from "react-router-dom";
import "./main.css";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <HashRouter>
    <App />
  </HashRouter>
);
/*
      <Route path="/" element={<App />} />
      <Route path='/fragen/:id' element={<Fragen />} />
      <Route path='/questions' element={<AdminQuestions />} />
      <Route path='/questions/myfragen' element={<MyFragen />} />
      <Route path='/questions/:id/fragen' element={<FrageStellen />} />
      <Route path='/login' element={<Login />} />
      <Route path='/home' element={<Home />} />
*/
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
