import { useLocation } from "react-router-dom";

export default function Images() {
  const location = useLocation();
  let id = location.pathname.split("/")[1];

  return (
    <div className="d-flex justify-content-center">
      <h1>Images</h1>
    </div>
  );
}
