import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Layout, SidebarMenu, SidebarMenuItem } from "blue-react";

import {
  Check,
  BoxArrowLeft,
  InfoCircle,
  ExclamationTriangle,
  House,
  UiChecks,
} from "react-bootstrap-icons";
import "./../App.css";

import { List } from "react-bootstrap-icons";
// checking if the pathname includes either /meinefragen or /questions
// and only showing the menuitems if the condition is met
const CheckForLogin = ({ children }) => {
  const location = useLocation();

  const [showSidebar, setShowSideBar] = useState(false);
  const [enableHeader, setEnableHeader] = useState(false);
  useEffect(() => {
    console.log("this is the location: ", location);
    if (
      location.pathname === "/House" ||
      location.pathname === "/editor" ||
      location.pathname.includes("/home") ||
      location.pathname.includes("/add") ||
      location.pathname.includes("/creator") ||
      location.pathname.includes("/edit")
    ) {
      setShowSideBar(true);
    } else {
      setShowSideBar(false);
    }

    if (location.pathname.includes("/creator")) {
      setEnableHeader(true);
    } else {
      setEnableHeader(false);
    }
  }, [location]);

  // ...

  return showSidebar ? (
    <Layout
      sidebarToggleIconComponent={<List />}
      statusIcons={{
        danger: <ExclamationTriangle />,
        info: <InfoCircle />,
        success: <Check />,
        warning: <ExclamationTriangle />,
      }}
      {...(enableHeader ? {} : { disableHeaders: true })}
      expandSidebar
    >
      <SidebarMenu
        sidebarClass="overflow-visible"
        menuClass="overflow-visible"
        bottomContent={
          <>
            <SidebarMenuItem
              href={"/#/logout"}
              icon={<BoxArrowLeft style={{ fontSize: "1.4em" }} />}
              label={"Logout"}
            />
          </>
        }
      >
        <SidebarMenuItem
          href={"/#/home"}
          icon={<House style={{ fontSize: "1.4em" }} />}
          label={"Home"}
        />

        <SidebarMenuItem
          href={"/#/creator"}
          icon={<UiChecks style={{ fontSize: "1.4em" }} />}
          label={"Creator"}
        />
      </SidebarMenu>
      {children}
    </Layout>
  ) : (
    <Layout
      sidebarToggleIconComponent={<List />}
      statusIcons={{
        danger: <ExclamationTriangle />,
        info: <InfoCircle />,
        success: <Check />,
        warning: <ExclamationTriangle />,
      }}
      disableHeaders
      hideSidebarMenu
    >
      {children}
    </Layout>
  );
};

export default CheckForLogin;
