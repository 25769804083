import { Page, Header, HeaderTitle, Body } from "blue-react";
import { Plus } from "react-bootstrap-icons";
import "./../App.css";
import DetailList from "../components/DetailList";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
const HomePage = () => {
  const [wordPress, setWordpress] = useState("https://patorg.de");
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  async function valToken() {
    let token = localStorage.getItem("token");

    if (token === null) {
      token = cookies.token;
    }

    const r = await fetch(wordPress + "/wp-json/jwt-auth/v1/token/validate", {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
    });
    const c = await r.json();
    console.log(c);
    const cstatus = {
      status: r.status,
    };
    setStatus(cstatus.status);
    setLoading(false);
  }
  useEffect(() => {
    valToken();
  }, []);
  if (isLoading) {
    return (
      <Page>
        <Body>
          <div className="width-100 height-100 d-flex justify-content-center mx-auto p-2">
            <div className="spinner-border  " role="status">
              <span className="visually-hidden ">Loading...</span>
            </div>
          </div>
        </Body>
      </Page>
    );
  }
  return (
    <>
      {status === 200 ? (
        <Page className="body">
          <Body containerClass="container">
            <div className="d-flex justify-content-end mb-3">
              <a href="/#/creator" className="btn btn-light">
                <Plus style={{ verticalAlign: "-0.125em" }} /> Fragebogen
                hinzufügen
              </a>
            </div>
            <div className="d-flex">
              <DetailList />
            </div>
          </Body>
        </Page>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default HomePage;
