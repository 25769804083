import React, { useEffect, useState } from "react";
import { X } from "react-bootstrap-icons";
import { Eye, BoxArrowUp, Pencil } from "react-bootstrap-icons";
import {
  Card,
  CardTitle,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";
import { Caret } from "blue-react";
import { Survey } from "survey-react-ui";
const DetailList = (props) => {
  const [frageboegen, setFrageboegen] = useState([]);
  const [showPreview, setShowPreview] = useState(false);

  async function getFragen() {
    console.log("getting fragen", process.env.REACT_APP_BACKEND_URL);
    let res = await fetch(process.env.REACT_APP_BACKEND_URL + "/frageboegen");
    let frageboegen = await res.json();

    setFrageboegen(frageboegen);
    console.log(frageboegen);
  }

  async function deleteFragebogen(id) {
    console.log("deleting " + id);

    let res = await fetch(process.env.REACT_APP_BACKEND_URL + "/delete", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: id,
      }),
    });
    getFragen();
    window.location.reload(false);
  }

  const MyDropdown = (props) => {
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);
    return (
      <Dropdown isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle className="btn btn-light btn-sm">
          <Caret open={dropdownOpen} />
        </DropdownToggle>

        <DropdownMenu>
          <DropdownItem href={`/#/${props.id}`} target="__blank">
            <BoxArrowUp style={{ verticalAlign: "-0.125em" }} /> Öffnen
          </DropdownItem>
          <DropdownItem href={`/#/edit/${props.id}`}>
            <Pencil style={{ verticalAlign: "-0.125em" }} /> Bearbeiten
          </DropdownItem>
          <DropdownItem onClick={() => setShowPreview(true)}>
            <Eye style={{ verticalAlign: "-0.125em" }} /> Preview
          </DropdownItem>
          <DropdownItem divider />
          <DropdownItem onClick={() => deleteFragebogen(props.id)}>
            <X style={{ verticalAlign: "-0.125em" }} /> Löschen
          </DropdownItem>
        </DropdownMenu>
      </Dropdown>
    );
  };

  useEffect(() => {
    getFragen();
  }, []);

  return (
    <div style={{ minWidth: "30%" }}>
      {frageboegen.length > 0
        ? frageboegen.map((fragebogen) => (
            <>
              <Modal isOpen={showPreview} className="modal-xl">
                <ModalHeader toggle={() => setShowPreview(!showPreview)}>
                  Preview
                </ModalHeader>
                <ModalBody>
                  <Survey json={JSON.parse(fragebogen.json)} />
                </ModalBody>
              </Modal>
              <Card body className="mb-2">
                <CardTitle className="fw-bold">
                  <div className="d-flex justify-content-between">
                    <p>{fragebogen.name}</p>
                    <MyDropdown id={fragebogen.id} />
                  </div>
                </CardTitle>
              </Card>
            </>
          ))
        : "Keinen Fragebogen gefunden."}
    </div>
  );
};

export default DetailList;
