import {
  Body,
  Page,
  Caret,
  Header,
  ActionMenu,
  MenuItem,
  HeaderTitle,
} from "blue-react";
import React, { useEffect, useState } from "react";
import "survey-core/defaultV2.min.css";
import { Model } from "survey-core";
import { Survey } from "survey-react-ui";
import "survey-core/i18n/german";

import {
  Gear,
  Pencil,
  Plus,
  X,
  CaretLeftFill,
  CaretRightFill,
  Save,
} from "react-bootstrap-icons";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";

import { PlainLight } from "survey-core/themes/plain-light";
// code mirror
import CodeMirror from "@uiw/react-codemirror";
import { githubDark, githubLight } from "@uiw/codemirror-themes-all";
import { langs } from "@uiw/codemirror-extensions-langs";
import { useLocation, useNavigate } from "react-router-dom";
import { useCookies } from "react-cookie";
import { themeJson } from "./theme";

const SurveyEditor = (props) => {
  const [pageName, setPageName] = useState("");
  const [cookies, setCookie, removeCookie] = useCookies(["cookie-name"]);
  const [name, setName] = useState("");
  const [id, setId] = useState("");
  const location = useLocation();
  const [questionName, setQuestionName] = useState("");
  const [questionType, setQuestionType] = useState("");
  const [questionStartFrom, setQuestionStartFrom] = useState("");
  const [questionEndAt, setQuestionEndAt] = useState("");
  const [questionStandartValue, setQuestionStandartValue] = useState("");
  const [questionDescription, setQuestionDescription] = useState("");
  const [questionIsRequired, setQuestionIsRequired] = useState(false);
  const [questionQuestion, setQuestionQuestion] = useState("");
  const [questionMail, setQuestionMail] = useState("");
  const [questionSelectAll, setQuestionSelectAll] = useState(false);
  const [questionPlaceholder, setQuestionPlaceholder] =
    useState("+00 000 0000 0000");
  const [showAddQuestionModal, setShowAddQuestionModal] = useState(false);
  const [showAddPageModal, setShowAddPageModal] = useState(false);
  const [showEditConditionModal, setShowEditConditionModal] = useState(false);

  const [showSettingsModal, setShowSettingsModal] = useState(false);

  const [newOption, setNewOption] = useState("");
  const [status, setStatus] = useState("");
  const [isLoading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [wordPress, setWordPress] = useState("https://patorg.de");
  const [answerOptions, setAnswerOptions] = useState([
    "Beispiel1",
    "Beispiel2",
  ]);
  const [inputType, setInputType] = useState("");

  const [surveyJson, setSurveyJson] = useState({});
  const [survey, setSurvey] = useState(new Model(surveyJson));
  survey.applyTheme(themeJson);
  survey.locale = "de";
  const [pageNo, setPageNo] = React.useState(survey.currentPageNo + 1);
  const [isRunning, setIsRunning] = React.useState(true);
  survey.onCurrentPageChanged.add((_, options) => {
    setPageNo(options.newCurrentPage.visibleIndex);
  });
  survey.onStarted.add(() => {
    setIsRunning(true);
  });
  survey.onComplete.add(() => {
    setIsRunning(false);
  });
  survey.onComplete.add((sender, options) => {
    console.log(JSON.stringify(sender.data, null, 3));
  });
  const prevPage = () => {
    survey.prevPage();
  };
  const nextPage = () => {
    survey.nextPage();
  };
  const endSurvey = () => {
    survey.completeLastPage();
  };
  const jumpToPage = (pageNom) => {
    if (survey.currentPage.validate()) {
      setPageNo(pageNom);
    }
  };

  useEffect(() => {
    console.log("now on page", pageNo);
  }, [pageNo]);

  // survey.applyTheme(PlainLight);
  function addOption() {
    let newOptions = [...answerOptions];
    newOptions.push(newOption);
    setAnswerOptions(newOptions);
  }

  function addQuestion(index) {
    console.log("adding question to", index);
    let newSurveyJson = surveyJson;
    if (questionType === "radiogroup" || questionType === "dropdown") {
      newSurveyJson.pages[index].elements.push({
        type: questionType,
        name: questionName,
        title: questionQuestion,
        choices: answerOptions,
      });
    } else if (questionType === "text") {
      newSurveyJson.pages[index].elements.push({
        type: questionType,
        name: questionName,
        title: questionQuestion,
      });
    } else if (questionType === "numeric") {
      newSurveyJson.pages[index].elements.push({
        type: "text",
        name: questionName,
        inputType: inputType,
        title: questionQuestion,
        min: questionStartFrom,
        max: questionEndAt,
        defaultValue: questionStandartValue,
        isRequired: questionIsRequired,
        placeholder: questionPlaceholder,
      });
    } else if (questionType === "checkbox") {
      newSurveyJson.pages[index].elements.push({
        type: questionType,
        name: questionName,
        title: questionQuestion,
        choices: answerOptions,
        isRequired: questionIsRequired,
        description: questionDescription,
        showSelectAllItem: questionSelectAll,
      });
    } else if (questionType === "panel") {
      newSurveyJson.pages[index].elements.push({
        type: questionType,
        name: questionName,
        title: questionQuestion,
        elements: [],
      });
    } else if (questionType === "boolean") {
      newSurveyJson.pages[index].elements.push({
        type: questionType,
        name: questionName,
        title: questionQuestion,
      });
    }

    setSurveyJson({ ...surveyJson, pages: newSurveyJson.pages });
    setSurvey(new Model(newSurveyJson));
  }

  function addPage() {
    let newSurveyJson = surveyJson;
    newSurveyJson.pages.push({
      name: pageName,
      elements: [
        {
          type: "text",
          name: "Test Frage",
          title: "Test Frage:",
        },
      ],
    });
    console.log("newServay", newSurveyJson);
    setSurveyJson({ ...surveyJson, pages: newSurveyJson.pages });
    setSurvey(new Model(newSurveyJson));
    // setRender(false);
  }
  function save() {
    console.log(`Sending ${name} and \n ${JSON.stringify(surveyJson)}`);
    const save = fetch(process.env.REACT_APP_BACKEND_URL + "/edit", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        id: location.pathname.split("/")[2],
        json: JSON.stringify(surveyJson),
        name: name,
        sendto: questionMail,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // Handle the response from the backend
        console.log(data);
      })
      .catch((error) => {
        // Handle any errors
        console.error(error);
      });

    alert("Fragebogen wurde gespeichert");
  }

  const AddQuestionWithOptions = (props) => {
    const [newOption, setNewOption] = useState("");

    function addOption() {
      let newOptions = [...answerOptions];
      newOptions.push(newOption);
      setAnswerOptions(newOptions);
    }

    return (
      <div>
        <div>
          <strong>Answer Options</strong>
          {answerOptions.map((option, index) => (
            <>
              <div className="d-flex justify-content-between mb-1">
                <input
                  value={option}
                  className="form-control m-1"
                  onChange={(e) => {
                    let newOptions = [...answerOptions];
                    newOptions[index] = e.target.value;
                    setAnswerOptions(newOptions);
                  }}
                />
                <button
                  className="btn btn-light btn-sm m-1"
                  onClick={() => {
                    let newOptions = [...answerOptions];
                    newOptions.splice(index, 1);
                    setAnswerOptions(newOptions);
                  }}
                >
                  <X style={{ verticalAlign: "-0.125em" }} />
                </button>
              </div>
            </>
          ))}
        </div>
        <div className="d-flex">
          <input
            value={newOption}
            className="form-control w-50 m-1"
            onChange={(e) => setNewOption(e.target.value)}
          />
          <br />
          <button
            className="btn btn-light btn-sm m-1"
            onClick={() => addOption()}
          >
            <Plus style={{ verticalAlign: "-0.125em" }} />
          </button>
        </div>
      </div>
    );
  };

  async function getJSON() {
    let id = location.pathname.split("/")[2];
    let res = await fetch(process.env.REACT_APP_BACKEND_URL + "/frageboegen");
    let frageboegen = await res.json();

    let fragebogen = frageboegen.filter((fragebogen) => fragebogen.id === id);
    console.log("fragebogen", fragebogen[0]);
    setId(fragebogen[0].id);
    setName(fragebogen[0].name);
    setQuestionMail(fragebogen[0].sendto);
    setSurveyJson(JSON.parse(fragebogen[0].json));
    setSurvey(new Model(JSON.parse(fragebogen[0].json)));
  }

  // validating login token
  async function valToken() {
    let token = localStorage.getItem("token");
    if (token === null) {
      token = cookies.token;
    }
    const r = await fetch(wordPress + "/wp-json/jwt-auth/v1/token/validate", {
      method: "POST",
      headers: { Authorization: "Bearer " + token },
    });
    const c = await r.json();
    console.log(c);
    const cstatus = {
      status: r.status,
    };
    setStatus(cstatus.status);
    setLoading(false);
  }
  useEffect(() => {
    valToken();
    getJSON();
  }, []);
  if (isLoading) {
    return (
      <Page>
        <Body>
          <div className="width-100 height-100 d-flex justify-content-center mx-auto p-2">
            <div className="spinner-border  " role="status">
              <span className="visually-hidden ">Loading...</span>
            </div>
          </div>
        </Body>
      </Page>
    );
  }
  return (
    <>
      {status === 200 ? (
        <Page className="body">
          <Header>
            <HeaderTitle appTitle="Questions">
              Fragebogen bearbeiten
            </HeaderTitle>
            <ActionMenu className="" break="none">
              <div className="position-relative">
                <MenuItem
                  label={"Hinzufügen"}
                  supportOutside
                  dropdownClassName="end-0"
                >
                  <MenuItem
                    label={"Frage Hinzufügen"}
                    onClick={() => setShowAddQuestionModal(true)}
                  />
                  <MenuItem
                    label={"Seite Hinzufügen"}
                    onClick={() => setShowAddPageModal(true)}
                  />
                </MenuItem>
              </div>
              <div className="d-flex">
                <MenuItem
                  icon={<CaretLeftFill className="bi" />}
                  onClick={() => prevPage()}
                />
                <MenuItem
                  icon={<CaretRightFill className="bi" />}
                  onClick={() => nextPage()}
                />
                <MenuItem
                  className="blue-tooltip-down"
                  data-tooltip="Konfigurieren"
                  icon={<Pencil className="bi" />}
                  onClick={() =>
                    setShowEditConditionModal(!showEditConditionModal)
                  }
                />
                <MenuItem
                  className={"blue-tooltip-down"}
                  data-tooltip="Einstellungen"
                  onClick={() => setShowSettingsModal(!showSettingsModal)}
                  icon={<Gear className="bi" />}
                />
                <MenuItem
                  icon={<Save />}
                  onClick={() => save()}
                  className="blue-tooltip-down"
                  data-tooltip="Speichern"
                />
              </div>
            </ActionMenu>
          </Header>
          <Body>
            <div className="d-flex justify-content-between">
              <div className="m-1">
                {/* The modal to create a new page*/}
                <Modal isOpen={showAddPageModal}>
                  <ModalHeader
                    toggle={() => {
                      setShowAddPageModal(!showAddPageModal);
                      setPageName("");
                    }}
                  >
                    Add Page
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      <input
                        placeholder="Page Name"
                        className="form-control m-1"
                        onChange={(e) => setPageName(e.target.value)}
                      />
                      <button
                        className="btn btn-secondary m-1"
                        onClick={() => {
                          addPage();
                          setShowAddPageModal(false);
                          setPageName("");
                        }}
                      >
                        Add Page
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
                {/* The modal to create a new question*/}
                <Modal isOpen={showAddQuestionModal}>
                  <ModalHeader
                    toggle={() => {
                      setShowAddQuestionModal(!showAddQuestionModal);
                      setQuestionName("");
                      setQuestionType("");
                      setQuestionEndAt("");
                      setQuestionStartFrom("");
                      setInputType("");
                      setQuestionQuestion("");
                      setQuestionSelectAll(false);
                    }}
                  >
                    Add Question
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      <input
                        placeholder="Question Name"
                        className="form-control m-1"
                        onChange={(e) => setQuestionName(e.target.value)}
                      />
                      <input
                        placeholder="Question"
                        className="form-control m-1"
                        onChange={(e) => setQuestionQuestion(e.target.value)}
                      />
                      <select
                        className="form-control m-1"
                        onChange={(e) => setQuestionType(e.target.value)}
                      >
                        <option selected disabled>
                          Select type...
                        </option>
                        <option value={"boolean"}>boolean</option>
                        <option value={"text"}>text</option>
                        <option value={"radiogroup"}>radiogroup</option>
                        <option value={"numeric"}>numeric</option>
                        <option value={"dropdown"}>dropdown</option>
                        <option value={"checkbox"}>checkbox</option>
                        <option value={"panel"}>panel</option>
                      </select>
                      {questionType === "radiogroup" ? (
                        <AddQuestionWithOptions />
                      ) : questionType === "dropdown" ? (
                        <AddQuestionWithOptions />
                      ) : questionType === "numeric" ? (
                        <div>
                          <select
                            className="form-control m-1"
                            onChange={(e) => setInputType(e.target.value)}
                          >
                            <option selected disabled>
                              Eingabemethode wählen
                            </option>
                            <option value={"number"}>Number</option>
                            <option value={"range"}>Range</option>
                            <option value={"tel"}>Phone</option>
                          </select>
                          <div>
                            {inputType === "number" ? (
                              <div>
                                <strong>Von/Bis</strong>
                                <div className="d-flex">
                                  <input
                                    className="w-25 form-control m-1"
                                    placeholder="0"
                                    onChange={(e) =>
                                      setQuestionStartFrom(e.target.value)
                                    }
                                  />
                                  <input
                                    className="w-25 form-control m-1"
                                    placeholder="100"
                                    onChange={(e) =>
                                      setQuestionEndAt(e.target.value)
                                    }
                                  />
                                </div>
                                <strong>Standart Value</strong>
                                <input
                                  className="form-control m-1 w-25"
                                  placeholder="50"
                                  onChange={(e) =>
                                    setQuestionStandartValue(e.target.value)
                                  }
                                />
                              </div>
                            ) : inputType === "range" ? (
                              <div>
                                <strong>Von/Bis</strong>
                                <div className="d-flex">
                                  <input
                                    className="w-25 form-control m-1"
                                    placeholder="0"
                                    onChange={(e) =>
                                      setQuestionStartFrom(e.target.value)
                                    }
                                  />
                                  <input
                                    className="w-25 form-control m-1"
                                    placeholder="100"
                                    onChange={(e) =>
                                      setQuestionEndAt(e.target.value)
                                    }
                                  />
                                </div>
                                <strong>Standart Value</strong>
                                <input
                                  className="form-control m-1 w-25"
                                  placeholder="50"
                                  onChange={(e) =>
                                    setQuestionStandartValue(e.target.value)
                                  }
                                />
                                <strong>Schritte</strong>
                                <input
                                  className="form-control m-1 w-25"
                                  placeholder="10"
                                />
                              </div>
                            ) : inputType === "tel" ? (
                              <div>
                                <strong>Platzhalter</strong>
                                <input
                                  className="form-control m-1 w-50"
                                  placeholder="+00 000 0000 0000"
                                  onChange={(e) =>
                                    setQuestionPlaceholder(e.target.value)
                                  }
                                />
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      ) : questionType === "checkbox" ? (
                        <div>
                          <div>
                            <strong>Antwort Möglichkeiten</strong>
                            {answerOptions.map((option, index) => (
                              <>
                                <div className="d-flex justify-content-between mb-1">
                                  <input
                                    value={option}
                                    className="form-control m-1"
                                    onChange={(e) => {
                                      let newOptions = [...answerOptions];
                                      newOptions[index] = e.target.value;
                                      setAnswerOptions(newOptions);
                                    }}
                                  />
                                  <button
                                    className="btn btn-light btn-sm m-1"
                                    onClick={() => {
                                      let newOptions = [...answerOptions];
                                      newOptions.splice(index, 1);
                                      setAnswerOptions(newOptions);
                                    }}
                                  >
                                    <X style={{ verticalAlign: "-0.125em" }} />
                                  </button>
                                </div>
                              </>
                            ))}
                            <div className="d-flex">
                              <input
                                value={newOption}
                                className="form-control w-50 m-1"
                                onChange={(e) => setNewOption(e.target.value)}
                              />
                              <br />
                              <button
                                className="btn btn-light btn-sm m-1"
                                onClick={() => addOption()}
                              >
                                <Plus style={{ verticalAlign: "-0.125em" }} />
                              </button>
                            </div>
                            <strong>Beschreibung</strong>
                            <input
                              className="form-control m-1 w-100"
                              placeholder="Bitte wählen sie ihre Lieblingsautomarken"
                              onChange={(e) =>
                                setQuestionDescription(e.target.value)
                              }
                            />
                            <strong>Optionen</strong>
                            <div className="d-flex">
                              <input
                                className="me-1"
                                type="checkbox"
                                style={{ verticalAlign: "-0.125em" }}
                                onChange={(e) => {
                                  setQuestionIsRequired(e.target.checked);
                                }}
                              />
                              <label check>Pflichtfeld</label>
                              <input
                                className="me-1"
                                type="checkbox"
                                style={{ verticalAlign: "-0.125em" }}
                                onChange={(e) =>
                                  setQuestionSelectAll(e.target.checked)
                                }
                              />
                              <label check>Alle auswählen Knopf</label>
                            </div>
                          </div>
                        </div>
                      ) : (
                        ""
                      )}
                      <button
                        className="btn btn-secondary m-1"
                        onClick={() => {
                          addQuestion(pageNo);
                          console.log(`Adding question to ${[pageNo]}`);
                          setShowAddQuestionModal(false);
                          setQuestionName("");
                          setQuestionType("");
                          setAnswerOptions(["Beispiel1", "Beispiel2"]);
                          setQuestionDescription("");
                          setInputType("");
                          setQuestionQuestion("");
                          setQuestionSelectAll(false);
                        }}
                      >
                        Add Question
                      </button>
                    </div>
                  </ModalBody>
                </Modal>
                <Modal isOpen={showSettingsModal}>
                  <ModalHeader
                    toggle={() => setShowSettingsModal(!showSettingsModal)}
                  >
                    Einstellungen
                  </ModalHeader>
                  <ModalBody>
                    <div>
                      <label>Fragebogen Name</label>
                      <input
                        className="form-control mb-1"
                        placeholder="Name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                      />
                      <label>E-Mail</label>
                      <input
                        className="form-control mb-1"
                        placeholder="E-Mail"
                        value={questionMail}
                        onChange={(e) => setQuestionMail(e.target.value)}
                      />
                    </div>
                  </ModalBody>
                </Modal>
              </div>
            </div>
            <div className="w-100">
              <>
                <div
                  className={`${showEditConditionModal ? "d-flex" : ""} mt-5`}
                >
                  <div className="w-50">
                    {showEditConditionModal && (
                      <>
                        <CodeMirror
                          value={JSON.stringify(surveyJson, null, 3)}
                          onChange={(editor, data, value) => {
                            try {
                              setSurveyJson(JSON.parse(editor));
                              setSurvey(new Model(JSON.parse(editor)));
                            } catch (e) {
                              console.log(e);
                            }
                          }}
                          className="flex-fill"
                          height="85vh"
                          maxHeight="85vh"
                          width="100%"
                          id="editor"
                          extensions={[langs.json()]}
                          theme={githubLight}
                          basicSetup={{
                            foldGutter: false,
                            dropCursor: false,
                            allowMultipleSelections: false,
                            indentOnInput: false,
                          }}
                        />
                      </>
                    )}
                  </div>
                  <div
                    className={`${showEditConditionModal ? "w-50" : "w-100"}`}
                  >
                    <Survey model={survey} />
                  </div>
                </div>
              </>
            </div>
          </Body>
        </Page>
      ) : (
        navigate("/")
      )}
    </>
  );
};

export default SurveyEditor;
